import React from 'react';
import "../styles.css";
import AboutPhotos from "../../assets/abus-about.png";


function About() {
  return (
    <div class="section-about">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <img src={AboutPhotos} class="about-photo" alt="Polariod Photo's of Abu's restaurant" width="100%" />
          </div>
          
          <div class="col-md-8">

            <h1>About</h1>
             
            <p>Welcome to Abu's, the home of "Apna" Indian Cuisine, where "Apna" means ours, reflecting our dedication to bringing you the most delicious Indian food that celebrates our heritage and culture.</p>
    
            <p>Managed by Abu Raihan and his father Chef Abdul Mannan, our journey began in November 2019, amidst the rich culinary traditions of Indian cuisine. Through the challenges of the Covid-19 pandemic, we've persevered, adapting and thriving to bring you exceptional dining experiences.</p>
            
            <p>As a family-run establishment and a proud independent business, we prioritise delivering not only great food but also exceptional service. Our commitment extends beyond the walls of our restaurant as we actively engage with our community, sponsoring local sports teams, partnering with the NHS for fundraisers, and standing by our community through thick and thin.</p>
            
            <p>Hailing from Bradford, the renowned "Curry Capital" of England, Abu and Abdul are deeply rooted in their local culture and community. This pride and connection inspired us to rebrand from Akbar The Great to Abu's.</p>
            
            <p>But why Abu's, you may wonder? Allow us to explain:</p>
            
            <ul>
              <li><strong>It's Personal:</strong> Abu isn't just a name; it's my first name. By putting my name on this business, I've poured my heart and soul into creating an unforgettable dining experience.</li>
              <li><strong>Legacy Love:</strong> I'm driven by the desire to leave a lasting legacy, especially for my daughters. Abu's is not just a restaurant; it's a testament to my dedication and passion.</li>
              <li><strong>Fatherly Feels:</strong> In Arabic, Abu means father, symbolising the nurturing and welcoming environment we strive to create for families to come together over delicious food.</li>
            </ul>
            
            <p>At Abu's, we blend great food with family values to craft the ultimate dining experience. Join us and taste the difference firsthand.</p>

          </div>
        </div>
      </div>
      </div>
  );
}

export default About;
