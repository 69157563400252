import React from "react";
import hero1 from "../../assets/abus-cover-left.png";
import hero2 from "../../assets/abus-cover-right.png";
import dineInMenu from "../../assets/Abu's Dine In Menu - May 2024.pdf";
import "../styles.css";


function Carousel() {
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
      <div class="carousel-inner">
        <div class="carousel-item active">
          
        <div class="d-flex justify-content-between align-items-center">
    <div>
      <img src={hero1} class="cImg d-block w-100" alt="Abu's Left" />
    </div>
    <div>
      <img src={hero2} class="cImg d-block w-100" alt="Abu's Right" />
    </div>
  </div>

          <div class="carousel-caption d-md-flex justify-content-center align-items-center">
            <div class="text-center">
              <h1 class="hero-heading">Home Of Apna Indian Cuisine</h1>

              <div class="hero-buttons row d-none d-md-flex justify-content-center">
              <div class="col-md-auto">
                  <a href="https://abusrestaurant.co.uk/static/media/Abu's Dine In Menu - May 2024.43932139.pdf" class="hero-link" alt="Link to Dine In Menu PDF">
                    <button type="button" class="hero-button">View Inside Menu</button>
                  </a>
                </div>
                <div class="col-md-auto">
                  <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                    <button type="button" class="hero-button">Order Online</button>
                  </a>
                </div>

              </div>

              <div class="d-md-none">
              <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                  <button type="button" class="hero-button">View Inside Menu</button>
                </a>

                <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                  <button type="button" class="hero-button">Order Online</button>
                </a>

              </div>
              </div>
            
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Carousel;
